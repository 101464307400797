<template>
  <!-- send-invoice-modal -->
  <b-modal
    id="send-invoice-modal"
    ref="SendInvoiceModal"
    :title="getStatus !== 'Sent' ? (isQuote ? 'Send Quote' : 'Send Invoice') : 'Send Reminder'"
    size="md"
    header-class="font-primary"
    header-bg-variant="light"
    header-text-variant="main-green"
    header-border-variant="white"
    footer-bg-variant="light"
    footer-border-variant="white"
    @hide="closeModal()"
  >
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <span
          class="text-left text-dark font-primary font-weight-normal state-text"
          v-if="!stripeComplete">To enable direct payments via Stripe, please take a moment to link your Stripe account. It's easy—just <a href="/profile">click here</a> to seamlessly connect your account.
          </span>
      <div
        v-if="!isLoadingLink"
        class="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end mx-0 w-100"
      >
        <b-button
          variant="gray-550"
          pill
          class="my-2 my-sm-0 mr-0 mr-sm-2 border-0 bg-ghost-white action-btn"
          @click="cancel"
        >
          Cancel
        </b-button>
        <b-button
          variant="main-green"
          pill
          class="my-2 my-sm-0 ml-0 ml-sm-2 border-0 action-btn"
          @click="sendInvoice()"
        >
        <span v-if="!LoadingInvoice">Send</span> <!-- Show "Send" text when not sending -->
    <b-spinner v-else class="align-middle"></b-spinner>
        </b-button>
      </div>
      <div v-else>
        <b-button
          variant="gray-550"
          pill
          class="my-2 my-sm-0 mr-0 mr-sm-2 border-0 bg-ghost-white action-btn"
          @click="cancel"
        >
          Cancel
        </b-button>
        <b-button
          v-clipboard:copy="textToCopy"
          variant="main-green"
          pill
          class="my-2 my-sm-0 ml-0 ml-sm-2 border border-main-green text-main-green bg-white action-btn"
          @click="copyUrl()"
        >
          Copy only
        </b-button>
        <b-button
          v-clipboard:copy="textToCopy"
          variant="main-green"
          pill
          class="my-2 my-sm-0 ml-0 ml-sm-2 border-0 action-btn"
          @click="copyUrlAndSend()"
        >
          Copy and mark as sent
        </b-button>
      </div>
    </template>

    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <b-icon
        icon="x-circle"
        class="text-dark"
        @click="closeModal()"
        aria-label="Close Modal"
        font-scale="0.8"
      ></b-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100 my-3 my-md-2 px-1 py-1"
      no-gutters
    >
      <b-tabs
        active-nav-item-class="font-weight-bold text-main-green"
        class="customer-tabs w-100"
        nav-class="tab-wrapper"
        content-class="my-3"
        v-model="isLoadingLink"
      >
        <b-tab title="Email" title-link-class="tab-nav-link" active>
          <!-- full row input (Customer) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 pt-4 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Customer Name
              </span>
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="user-name-group"
                id="user-name-group-input"
                class="text-prime-gray rounded-2"
                v-model="from"
                :state="fromError.status"
                :invalidFeedback="fromError.message"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (Full name) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >To</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="user-name-group"
                id="user-name-group-input"
                class="text-prime-gray rounded-2"
                v-model="to"
                :state="toError.status"
                :invalidFeedback="`${toError.message}`"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (Phone) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Subject</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="user-name-group"
                id="user-name-group-input"
                class="text-prime-gray rounded-2"
                v-model="subject"
                :state="subjectError.status"
                :invalidFeedback="subjectError.message"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <!-- full row input (E-mail) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
            no-gutters
          >
            <b-col cols="4">
              <span class="text-left text-dark font-primary font-weight-normal"
                >Note</span
              >
            </b-col>
            <b-col cols="8">
              <FormInput
                groupId="user-name-group"
                id="user-name-group-input"
                class="text-prime-gray rounded-2"
                v-model="note"
                isRequired
                isTextArea
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 px-2 py-4"
            no-gutters
          >
            <b-col
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <b-form-checkbox
                id="checkbox-1"
                v-model="copy"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
              >
                Send a copy to myself at {{ getUserEmail }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <!-- check box to upload invoice to mail -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 px-2 py-4"
            no-gutters
          >
            <b-col
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <b-form-checkbox
                id="checkbox-2"
                v-model="sendInvoicechkox"
                name="checkbox-2"
                :value="true"
                :unchecked-value="false"
              >
               {{ isQuote ? "Send the quote as PDF file." : "Send the invoice as PDF file."  }} 
              </b-form-checkbox>
            </b-col>
          </b-row>
          <!-- Button upload pdf -->

          <!-- <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 px-2 py-4"
            no-gutters
          >
            <b-col
              class="d-flex flex-column align-items-start justify-content-start"
            >
              
              <b-icon
                variant="white"
                icon="circle-fill"
                size="sm"
                animation="throb"
                font-scale="1.4"
                v-if="checkout_active"
              ></b-icon>
              <span class="d-inline ml-2" v-if="!checkout_active"
                >Upload a file</span
              >
              <div class="d-inline">
                <span class="d-inline ml-2" v-if="checkout_active"
                  >Uploading....</span
                >
                <b-icon
                  class="d-inline"
                  variant="green"
                  icon="circle-fill"
                  size="sm"
                  animation="throb"
                  font-scale="1.4"
                  v-if="checkout_active"
                ></b-icon>
              </div>

              <input
                class="file-input"
                ref="fileInput"
                type="file"
                @input="onSelectFile"
              />

            
            </b-col>
          </b-row> -->
        </b-tab>
        <b-tab
          title="Shared link"
          title-link-class="tab-nav-link"
          :active="getIsActiveShare"
        >
          <!-- full row input (No :) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-end w-100 pt-4 px-2"
            no-gutters
          >
            <b-col cols="12">
              <FormInput
                groupId="user-name-group"
                id="user-name-group-input"
                class="text-prime-gray rounded-2"
                label=""
                v-model="invoiceShareLink"
                isRequired
                :whiteBG="false"
                trim
                form="login-form"
                type="text"
                isDisabled
              ></FormInput>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-row>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import VueClipboard from "vue-clipboard2";
// components
import FormInput from "@/components/Form/FormInput";

// services

import {
  InvoiceShareEmail,
  ReminderEmail,
  PdfUpload,
} from "@/services/invoice.service";
import { InvoiceShareLink } from "@/services/invoice.service";
import { UpdateInvoice } from "@/services/invoice.service";
import CheckEmail from "@/util/CheckEmail";
import {checkStripAccountStatus} from "@/services/stripe.service";

export default {
  name: "AddCustomerModal",
  // mixins: [VueClipboard.mixin],
  VueClipboard,
  props: {
    status: {
      default: null,
    },
    itemContent: {
      default: null,
    },
    shareUrl: {
      default: null,
    },
    perPage: {
      type: Number,
      default: 5,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  components: {
    FormInput,
  },
  data() {
    return {
      textToCopy: "Text to copy",
      from: null,
      to: null,
      subject: null,
      selectedFile: null,
      SelectedFileName: null,
      fileFormat: null,
      checkout_active: false,
      note: "Dear Customer, \nPlease find your invoice and your due date. Prompt payment will mean you are ranked amongst our top customers.\nThank You.",
      copy: false,
      sendInvoicechkox: false,
      invoiceShareLink: null,
      isLoadingLink: true,
      toError: {
        status: null,
        message: "",
      },
      subjectError: {
        status: null,
        message: "",
      },
      fromError: {
        status: null,
        message: "",
      },
      stripeComplete:false,
      LoadingInvoice: false
    };
  },
  watch: {
    shareUrl(val) {
      this.invoiceShareLink = val;
      console.log(this.shareUrl);
    },
    to(val) {
      if (val) {
        if (CheckEmail(val) == true) this.toError.status = true;
        else {
          this.toError.status = false;
          this.toError.message = "Please enter a valid email address";
        }
      } else {
        this.toError.status = false;
        this.toError.message = "Please fill out this field";
      }
    },
    from(val) {
      if (val) {
        this.fromError.status = true;
      } else {
        this.fromError.status = false;
        this.fromError.message = "Please fill out this field";
      }
    },
    subject(val) {
      if (val) {
        this.subjectError.status = true;
      } else {
        this.subjectError.status = false;
        this.subjectError.message = "Please fill out this field";
      }
    },
  },
  computed: {
    ...mapGetters({
      getIsActiveShare: "invoiceCreate/getIsActiveShare",
      getUserEmail: "auth/getEmail",
    }),
    getStatus() {
      return this.status;
    },
    isQuote() {
      return window.location.href.toString().includes("quotes");
    },
   
  },
  async mounted() {
    
    await this.initFn();
    if (this.isQuote) {
      this.note = "Dear Customer, \nPlease find your quote and your due date. Prompt payment will mean you are ranked amongst our top customers.\nThank You."
    } else {
      this.note = "Dear Customer, \nPlease find your invoice and your due date. Prompt payment will mean you are ranked amongst our top customers.\nThank You."
    }
  },
  methods: {
    closeModal() {
      this.$emit("onClose");
      
    },
    copyUrl() {
      // navigator.clipboard.readText(this.InvoiceShareLink);
      // console.log("read link", navigator.clipboard.readText(this.InvoiceShareLink));
      // navigator.clipboard.writeText(this.invoiceShareLink);
      this.textToCopy = this.invoiceShareLink;
      // console.log("copy link");
      // const textField = document.createElement("textarea");
      // textField.innerText = this.invoiceShareLink;
      // document.body.appendChild(textField);
      // textField.select();
      // document.execCommand("copy");
      // textField.remove();

      if (this.invoiceShareLink) {
        let payloadNotify = {
          isToast: true,
          title: "Link Copied!",
          content: "The link has been copied successfully",
          variant: "success",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    async copyUrlAndSend() {
      
      this.textToCopy = this.invoiceShareLink;
      if (this.invoiceShareLink) {
        let payload = {
          id: this.itemContent,
          status: "sent",
        };
        try {
          await UpdateInvoice(payload);
          let payloadNotify = {
            isToast: true,
            title: "Link Copied!",
            content: "The link has been copied and sent successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.$emit("loadTable");
        } catch (error) {
          console.log(error);
        }
      }
    },
    async initFn() {
      try {
        await InvoiceShareLink();
      } catch (error) {
        console.log(error);
      }
      const { data } = await checkStripAccountStatus();
      this.stripeComplete = data.stripeOnboardComplete;
    },
    async sendInvoice() {
      if (this.checkout_active === false) { 
        this.LoadingInvoice = true
         if (this.getStatus === "Sent") {
          await this.sendReminder();
         
        }else{
          if (
          !this.subjectError.status ||
          !this.toError.status ||
          !this.fromError.status 
        ) {
          let payloadNotify = {
            isToast: true,
            title: "Failed!",
            content: "Please complete the fields",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
         
        }else{
        
                    let payload = {
                  invoice_id: this.itemContent,
                  to: this.to,
                  from: this.from,
                  subject: this.subject,
                  note: this.note,
                  copy: this.copy,
                  user_email: this.getUserEmail,
                  fileUrl: this.url,
                  sendInvoice: this.sendInvoicechkox,
                };
                console.log(typeof this.copy);
                console.log("payload",payload);

                // console.log(this.itemContent);
                try {
                  await InvoiceShareEmail(this.itemContent, payload);
                  if (this.isQuote){
                  let payloadNotify = {
                    isToast: true,
                    title: "Quote Sent!",
                    content: "The quote has been sent successfully",
                    variant: "success",
                  };
                  this.$store.dispatch("notification/setNotify", payloadNotify);
                  this.$root.$emit("bv::hide::modal", "send-invoice-modal");
                  this.$emit("reloadFn");
                } else {
                    let payloadNotify = {
                    isToast: true,
                    title: "Invoice Sent!",
                    content: "The invoice has been sent successfully",
                    variant: "success",
                  };
                  this.$store.dispatch("notification/setNotify", payloadNotify);
                  this.$root.$emit("bv::hide::modal", "send-invoice-modal");
                  this.$emit("reloadFn");
                  }
                
                 

                } catch (error) {
                  console.log(error);
                  if(!this.isQuote){
                    let payloadNotify = {
                    isToast: true,
                    title: "Failed!",
                    content: "failed to share invoice with " + this.to,
                    variant: "error",
                  };
                  this.$store.dispatch("notification/setNotify", payloadNotify);
                  }else{
                    let payloadNotify = {
                    isToast: true,
                    title: "Failed!",
                    content: "failed to share quote with " + this.to,
                    variant: "error",
                  };
                  this.$store.dispatch("notification/setNotify", payloadNotify);
                  }
                 
                }
                this.LoadingInvoice = false
                this.resetfn()
                this.$emit('reloadFn');
         
        }
        }
        // if (this.getIsActiveShare && this.invoiceShareLink) {
        //   let payloadNotify = {
        //     isToast: true,
        //     title: "Invoice Shared!",
        //     content: "The invoice have been shared successfully",
        //     variant: "success",
        //   };
        //   this.$store.dispatch("notification/setNotify", payloadNotify);
        //   this.$root.$emit("bv::hide::modal", "send-invoice-modal");
        // } else {  
        // }
      } else {
        console.log("can't upload because still uploading file");
      }
    },
    async sendReminder() {
      if (this.checkout_active === false ) {
        if (
          !this.subjectError.status ||
          !this.toError.status ||
          !this.fromError.status 
        ) {
          let payloadNotify = {
            isToast: true,
            title: "Failed!",
            content: "Please complete the fields",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
         
        }else{
         
        let payload = {
          invoice_id: this.itemContent,
          to: this.to,
          from: this.from,
          subject: this.subject,
          note: this.note,
          copy: this.copy,
          user_email: this.getUserEmail,
          fileUrl: this.url,
          sendInvoice: this.sendInvoicechkox,
        };
        console.log("payload",payload);

        try {
          await ReminderEmail(this.itemContent, payload);
          let payloadNotify = {
            isToast: true,
            title: "Reminder Sent!",
            content: "Reminder has been sent successfully",
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          this.$root.$emit("bv::hide::modal", "send-invoice-modal");
          this.$emit("reloadFn");
        } catch (error) {
          console.log(error);
          let payloadNotify = {
            isToast: true,
            title: "Failed!",
            content: "failed to sent reminder to " + this.to,
            variant: "error",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        this.resetfn()
        this.$emit('reloadFn');
        
      }
      } else {
        console.log("can't upload because still uploading file");
      }
    },

    resetfn() {
         this.to = null,
         this.from = null,
          this.subject = null,
          this.copy = false;
         this.sendInvoicechkox = false;
           this.url = null


    },
    // async onSelectFile() {
    //   const fileInput = this.$refs.fileInput;
    //   if (fileInput.files.length > 0) {
    //     this.selectedFile = fileInput.files[0];
    //     this.SelectedFileName = this.selectedFile.name.replace(
    //       /\.(pdf|csv|xlsx)$/i,
    //       ""
    //     );
    //     this.fileFormat = this.selectedFile.name.split(".").pop();
    //     console.log("File Format:", this.fileFormat);
    //   } else {
    //     this.selectedFile = null;
    //   }

    //   if (this.selectedFile) {
    //     this.checkout_active = true;
    //     try {
    //       let formData = new FormData();
    //       formData.append("file", this.selectedFile);
    //       let response = await PdfUpload(formData);
    //       console.log(response);
    //       this.selectedFile = null;

    //       if (response.status === 200) {
    //         this.url = response.data.pdf;

    //         this.checkout_active = false;
    //       }
    //     } catch (error) {
    //       // this.checkout_active = false;
    //       this.isLoading = false;
    //     }
    //   }
    // },
    async onSelectFile() {
  const fileInput = this.$refs.fileInput;
  if (fileInput.files.length > 0) {
    let selectedFile = fileInput.files[0];

    if (selectedFile.size <= 5 * 1024 * 1024) {
      this.selectedFile = selectedFile;
      this.fileFormat = this.selectedFile.name.split(".").pop();
      console.log("File Format:", this.fileFormat);
    } else {
      this.selectedFile = null;
      this.checkout_active = false;
      alert("Please upload a file under 5 MB.");
      return;
    }
  } else {
    this.selectedFile = null;
  }

  if (this.selectedFile) {
    this.checkout_active = true;
    try {
      let formData = new FormData();
      formData.append("file", this.selectedFile);
      let response = await PdfUpload(formData);
      console.log(response);
      this.selectedFile = null;

      if (response.status === 200) {
        this.url = response.data.pdf;

        this.checkout_active = false;
      }
    } catch (error) {
      // this.checkout_active = false;
      this.isLoading = false;
    }
  }
},



  },
};
</script>

<style scoped>
.tab-wrapper {
  border-bottom: 2px solid var(--gray-750);
  /* border-bottom: none; */
}
.customer-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
}
.customer-tabs >>> .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 20px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray); */
}
.action-btn {
  height: 50px;
  min-width: 120px;
}
/* .file-input {
  display: none;
} */
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
