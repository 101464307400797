<template>
  <HomeLayout>
    <b-container
        class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- user insights row -->
      <b-row
          class="d-print-none d-flex flex-row align-items-stretch justify-content-center w-100"
          no-gutters
      >
        <b-card
            no-body
            class="position-relative overflow-hidden shadow-sm border-0 insight-cards-row w-100"
        >
          <template v-if="isAnalyticsLoading">
            <b-overlay :show="isAnalyticsLoading" no-wrap variant="white">
            </b-overlay>
          </template>
          <template v-else>
            <b-row
                class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 my-lg-3 px-3 w-100"
                no-gutters
            >
              <!-- Overdue column -->
              <b-col
                  class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                  cols="12"
                  md="6"
                  lg="3"
              >
                <h6
                    class="text-left text-truncate text-small font-weight-bold text-main-green"
                >
                  Status
                </h6>
                <span
                    class="text-left font-secondary font-weight-normal text-center bg-light text-gray-750 px-3 py-1 status-button"
                >
                  {{ invoiceDetails.status ? invoiceDetails.status : "Draft" }}
                </span>
              </b-col>
              <!-- Due within next 30 days column -->
              <template>
                <b-col
                    class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                    cols="12"
                    md="6"
                    lg="3"
                >
                  <h6
                      class="text-left text-truncate text-small font-weight-bold text-main-green"
                  >
                    Customer
                  </h6>
                  <h5
                      class="text-left font-secondary font-weight-bold text-dark"
                  >
                    {{
                      invoiceDetails.customerName
                          ? invoiceDetails.customerName
                          : "N/A"
                    }}
                  </h5>
                </b-col>
              </template>
              <!-- Average time column -->
              <b-col
                  class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                  cols="12"
                  md="6"
                  lg="3"
              >
                <h6
                    class="text-left text-truncate text-small font-weight-bold text-main-green"
                >
                  Total Amount
                </h6>
                <h5 class="text-left font-secondary font-weight-bold text-dark">
                  {{
                    invoiceDetails.totalAmount
                        ? numberFormat(invoiceDetails.totalAmount)
                        : 0
                  }}
                  {{ invoiceDetails.currency }}
                </h5>
                <h6
                    class="text-left text-truncate text-small font-weight-bold text-main-green"
                    v-if="invoiceDetails.conversion_rate"
                >
                  Currency conversion {{ (invoiceDetails.totalAmount / invoiceDetails.conversion_rate).toFixed(2) }}
                  ({{ invoiceDetails.anchor_currency }}) at {{invoiceDetails.conversion_rate}}
                </h6>
              </b-col>
              <!-- Upcoming payout column -->
              <b-col
                  class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                  cols="12"
                  md="6"
                  lg="3"
              >
                <h6
                    class="text-left text-truncate text-small font-weight-bold text-main-green"
                >
                  Due date
                </h6>
                <h5 class="text-left font-secondary font-weight-bold text-dark">
                  {{ formatDate(invoiceDetails.dueDate ? invoiceDetails.dueDate : "N/A") }}
                </h5>
              </b-col>
            </b-row>
          </template>
        </b-card>
      </b-row>
      <!-- table container row -->
      <b-row
          class=" d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
          no-gutters
      >
        <b-card no-body class="shadow-sm border-0 table-container-card w-100">
          <!-- table actions row -->
          <b-row
              class="d-print-none d-flex flex-row align-items-center justify-content-between w-100 my-1 my-md-2 px-lg-3 py-3"
              no-gutters
          >

            <!-- <b-col
                  class="d-flex flex-column align-items-start justify-content-center mr-2 py-2 py-lg-0"

              >
                <b-row
                    class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right pr-1"
                    no-gutters
                >
                <b-button
                       variant="light"
                      class="d-flex flex-row align-items-center justify-content-center text-main-green mx-0 mx-lg-2 my-2 my-lg-0 px-4 py-2 border border-main-green rounded-lg add-question-btn"
                      pill
                  >
                    <span class="d-inline ml-2">Preview PDF</span>
                  </b-button>
                <b-button
                      variant="main-green"
                      class="d-flex flex-row align-items-center justify-content-center mx-0 mx-lg-2 my-2 my-lg-0 px-4 py-2 border border-main-green rounded-lg add-question-btn"
                      pill
                  >
                    <span class="d-inline ml-2">Download PDF</span>
                  </b-button>
                </b-row>
                </b-col> -->
            <!-- more options / create invoice column  -->
            <b-col
                class="d-flex flex-column align-items-end justify-content-center mr-2 py-2 py-lg-0"

            >
              <b-row
                  class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right pr-1"
                  no-gutters
              >
                <!-- more options dropdown -->
                <b-dropdown
                    id="dropdown-form"
                    ref="dropdown"
                    variant="light"
                    class="mx-0 my-2 my-md-0 border-0 filter-dropdown"
                    toggle-class="d-flex flex-row align-items-center justify-content-center mx-0 my-2 my-lg-0 px-4 py-2 text-main-green border border-main-green rounded-pill"
                    menu-class="w-100 bg-white border border-main-green py-1 filter-dropdown-menu px-2"
                    right
                    no-caret
                    no-flip
                    @show="filterDropDownShow = true"
                    @hide="filterDropDownShow = false"
                    :disabled="getUserRole.role.includes('User')"
                >
                  <template v-slot:button-content>
                    <span class="d-inline mx-2">More options</span>
                  </template>
                  <b-dropdown-group flush class="rounded-lg py-2">
                    <b-dropdown-item-button
                        class="py-1"
                        @click="duplicateInvoice"
                    >Duplicate
                    </b-dropdown-item-button
                    >
                    <!-- <b-dropdown-item-button
                class="border border-primary border-right-0 border-left-0"
                @click="downloadInvoiceFn"
                ><span class=" py-2 mt-2 mb-2"
                  >Preview</span
                ></b-dropdown-item-button
              > -->

                    <b-dropdown-item-button
                        class="py-1"
                        @click="downloadInvoiceFn"
                    >Export as PDF
                    </b-dropdown-item-button
                    >
                    <!-- <b-dropdown-item-button  class="py-1" @click="downloadInvoiceFn"
                >Export as XLS</b-dropdown-item-button
              > -->
                    <b-dropdown-item-button class="py-1" @click="printInvoice()"
                    >Print
                    </b-dropdown-item-button
                    >
                    <b-dropdown-item-button
                        button-class="text-danger py-1"
                        @click="openDeleteInvoiceModal($event.target)"
                    >Delete
                    </b-dropdown-item-button
                    >
                  </b-dropdown-group>
                </b-dropdown>
                <b-button
                    variant="main-green"
                    class="d-flex flex-row align-items-center justify-content-center mx-0 mx-lg-2 my-2 my-lg-0 px-4 py-2 border border-main-green rounded-lg add-question-btn"
                    :to="limits.remaining_invoice === 0?'':'/invoices/create'"
                    pill
                    v-if="!isQuote"
                    :disabled="getUserRole.role === 'User'"
                >
                  <span class="d-inline ml-2">Create an invoice</span>
                  <b-icon
                      v-if="limits.remaining_invoice === 0&&!isQuote"
                      icon="lock-fill"
                      class=""
                      scale="1.1"
                      aria-hidden="true"
                      variant="warning"
                      title="You've reached to the maximum invoice limit on your package"
                  ></b-icon>
                </b-button>
                <b-button
                    variant="main-green"
                    class="d-flex flex-row align-items-center justify-content-center mx-0 mx-lg-2 my-2 my-lg-0 px-4 py-2 border border-main-green rounded-lg add-question-btn"
                    to="/quotes/create"
                    pill
                    v-if="isQuote"
                    :disabled="getUserRole.role === 'User'"
                >
                  <span class="d-inline ml-2">Create a quote</span>
                </b-button>
              </b-row>
            </b-col>
          </b-row>
          <!-- create invoice row -->
          <b-row
              class="d-print-none d-flex flex-row align-items-stretch justify-content-center w-100 px-3 mt-4"
              no-gutters
          >
            <b-card
                no-body
                class="position-relative overflow-hidden border-1 border-gray-550 create-invoice-row"
            >
              <b-row
                  class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 my-lg-3 pl-4 w-100"
                  no-gutters
              >
                <!-- create icon column  -->
                <b-col
                    class="d-flex flex-column align-items-start justify-content-center"
                    cols="12"
                    md="4"
                >
                  <b-row
                      class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"
                      no-gutters
                  >
                    <b-icon
                        class="text-main-green mr-1"
                        icon="pencil"
                        size="sm"
                        aria-label="Plus"
                        font-scale="3.0"
                    ></b-icon>
                    <h4 class="mx-4 font-weight-bold">Create</h4>
                  </b-row>
                </b-col>
                <!-- approve / edit column  -->
                <b-col
                    class="d-flex flex-column align-items-end justify-content-center"
                    cols="12"
                    md="8"
                >
                  <b-row
                      class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"
                      no-gutters
                  >
                    <template v-if="!this.getIsInvoiceApproved">
                      <!-- @hidden="onHidden" -->
                      <b-overlay
                          :show="isApprovedLoading"
                          rounded
                          opacity="0.6"
                          spinner-variant="main-green"
                          class="d-inline-block"
                      >
                        <b-button
                            variant="main-green"
                            class="d-flex flex-row align-items-center justify-content-center mx-0 mx-lg-1 my-2 my-lg-0 px-4 py-2 border border-main-green rounded-lg"
                            @click="approveInvoice"
                            pill
                            :disabled="getUserRole.role.includes('User')"
                        >
                          <span class="d-inline ml-2">{{
                              isQuote ? "Approve Quote" : "Approve Invoice"
                            }}</span>
                          <b-icon
                              v-if="limits.remaining_invoice === 0&&!isQuote"
                              icon="lock-fill"
                              class=""
                              scale="1.1"
                              aria-hidden="true"
                              variant="warning"
                              title="You've reached to the maximum invoice limit on your package"
                          ></b-icon>
                        </b-button>
                      </b-overlay>
                    </template>
                    <b-button
                        variant="main-green"
                        class="d-flex flex-row align-items-center justify-content-center mx-0 mx-lg-1 my-2 my-lg-0 px-4 py-2 border border-main-green text-main-green bg-white rounded-lg edit-invoice-btn"
                        @click="editInvoiceFn"
                        pill
                        :disabled="getUserRole.role === 'User'"
                    >
                      <span class="d-inline ml-2"
                      >Edit {{ isQuote ? "Quote" : "Invoice" }}</span
                      >
                    </b-button>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-row>
          <!-- send invoice row -->
          <b-row
              class="d-print-none d-flex flex-row align-items-stretch justify-content-center w-100 px-3 mt-3"
              no-gutters
          >
            <b-card
                no-body
                class="position-relative overflow-hidden border-1 border-gray-550 create-invoice-row w-100"
            >
              <b-row
                  class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 my-lg-3 pl-4 w-100"
                  no-gutters
              >
                <!-- create icon column  -->
                <b-col
                    class="d-flex flex-column align-items-start justify-content-center"
                    cols="12"
                    md="4"
                >
                  <b-row
                      class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"
                      no-gutters
                  >
                    <b-icon
                        class="text-main-green mr-1"
                        icon="cursor-fill"
                        size="sm"
                        aria-label="Plus"
                        font-scale="3.0"
                        :disabled="!this.getIsInvoiceApproved"
                        :class="
                        !this.getIsInvoiceApproved
                          ? 'text-gray-550'
                          : 'text-main-green'
                      "
                    ></b-icon>
                    <h4
                        class="mx-4 font-weight-bold"
                        :disabled="!this.getIsInvoiceApproved"
                        :class="
                        !this.getIsInvoiceApproved
                          ? 'text-gray-550'
                          : 'text-dark'
                      "
                    >
                      Send
                      {{ invoiceDetails.status === "Sent" ? "Reminder" : "" }}
                    </h4>
                  </b-row>
                </b-col>
                <!-- approve / edit column  -->
                <b-col
                    class="d-flex flex-column align-items-end justify-content-center"
                    cols="12"
                    md="8"
                >
                  <b-row
                      class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"
                      no-gutters
                  >
                    <b-button
                    v-if="invoiceDetails.status !== 'Paid' "
                        variant="main-green"
                        class="d-flex flex-row align-items-center justify-content-center mx-0 mx-lg-1 my-2 my-lg-0 px-4 py-2 rounded-lg"
                        @click="openSendInvoiceModal($event.target)"
                        pill
                        :disabled="!this.getIsInvoiceApproved || getUserRole.role === 'User'"
                        :class="
                        !this.getIsInvoiceApproved
                          ? 'disabled-button1'
                          : 'button-colour1'
                      "
                      
                    >
                      <span class="d-inline ml-2" 
                      >Send {{ isQuote ? "Quote" : "Invoice" }}
                        {{
                          invoiceDetails.status === "Sent" || invoiceDetails.status === "Partial" ? "Reminder" : ""
                        }}</span
                      >
                    </b-button>
                    <b-button
                        variant="white"
                        class="d-flex flex-row align-items-center justify-content-center mx-0 mx-lg-1 my-2 my-lg-0 px-4 py-2 rounded-lg"
                        @click="openShareLinkModal($event.target)"
                        pill
                        :disabled="!this.getIsInvoiceApproved || getUserRole.role === 'User'"
                        :class="
                        !this.getIsInvoiceApproved
                          ? 'disabled-button2'
                          : 'button-colour2'
                      "
                    >
                      <span class="d-inline ml-2">Get a link</span>
                    </b-button>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-row>
          <!-- get paid row -->
          <b-row
              class="d-print-none d-flex flex-row align-items-stretch justify-content-center w-100 px-3 mt-3"
              no-gutters
          >
            <b-card
                no-body
                class="position-relative overflow-hidden border-1 border-gray-550 create-invoice-row w-100"
            >
              <b-row
                  class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 my-lg-3 pl-4 w-100"
                  no-gutters
              >
                <!-- create icon column  -->
                <b-col
                    class="d-flex flex-column align-items-start justify-content-center"
                    cols="12"
                    md="4"
                >
                  <b-row
                      class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"
                      no-gutters
                  >
                    <b-icon
                        class="text-main-green mr-1"
                        icon="credit-card"
                        size="sm"
                        aria-label="Plus"
                        font-scale="3.0"
                        :disabled="!this.getIsInvoiceApproved"
                        :class="
                        !this.getIsInvoiceApproved
                          ? 'text-gray-550'
                          : 'text-main-green'
                      "
                    ></b-icon>
                    <h4
                        class="mx-4 font-weight-bold"
                        :disabled="!this.getIsInvoiceApproved"
                        :class="
                        !this.getIsInvoiceApproved
                          ? 'text-gray-550'
                          : 'text-dark'
                      "
                    >
                      Get Paid
                    </h4>
                  </b-row>
                </b-col>
                <!-- approve / edit column  -->
                <b-col
                    class="d-flex flex-column align-items-end justify-content-center"
                    cols="12"
                    md="8"
                >
                  <b-row
                      class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"
                      no-gutters
                  >
                    <!-- <b-button
                      variant="info"
                      class="d-flex flex-row align-items-center justify-content-center mx-0 mx-lg-1 my-2 my-lg-0 px-4 py-2 border border-secondary rounded-lg"
                      to="/invoices/create"
                      pill
                    >
                      <span class="d-inline ml-2">Approve Invoice</span>
                    </b-button> -->
                    <b-button
                        v-if="!isQuote && invoiceDetails.status !== 'Paid'"
                        variant="white"
                        class="d-flex flex-row align-items-center justify-content-center mx-0 mx-lg-1 my-2 my-lg-0 px-4 py-2 rounded-lg"
                        @click="openRecordPaymentModal($event.target)"
                        pill
                        :disabled="!this.getIsInvoiceApproved || getUserRole.role.includes('User')"
                        :class="
                        !this.getIsInvoiceApproved
                          ? 'disabled-button2'
                          : 'button-colour2'
                      "
                    >
                      <span class="d-inline ml-2">Record payment</span>
                    </b-button>
                    <b-button
                        v-if="isQuote"
                        variant="white"
                        class="d-flex flex-row align-items-center justify-content-center mx-0 mx-lg-1 my-2 my-lg-0 px-4 py-2 rounded-lg"
                        @click="convertToInvoice"
                        pill
                        :disabled="!this.getIsInvoiceApproved"
                        :class="
                        !this.getIsInvoiceApproved
                          ? 'disabled-button2'
                          : 'button-colour2'
                      "
                    >
                      <span class="d-inline ml-2">Create Invoice</span>

                    </b-button>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-row>
          <!-- invoice preview row -->
          <b-row
              id="content"
              class="d-flex flex-row align-items-center justify-content-start w-100 px-3 mt-3"
              no-gutters
          >
            <b-card
                no-body
                class="position-relative overflow-hidden border-0 space-top preview-invoice-row w-100"
                id="invoicePreview"
            >
              <template>
                <!-- <iframe :src="this.invoicePreviewUrl" height="500" width="600" title="Iframe Example"></iframe>  -->
                <div
                    class="preview-invoice print-invoice"
                    v-html="this.invoicePreviewUrl"
                ></div>
              </template>
            </b-card>
          </b-row>
        </b-card>
      </b-row>
      <SendInvoiceModal
          :shareUrl="this.invoiceShareUrl"
          :item-content="this.invoiceId"
          :status="this.invoiceDetails.status"
          @loadTable="refreshTable"
          @reloadFn="callInitFn"
      />
      <RecordPaymentModal
          :key="showRecordPaymentModalKey"
          :itemContent="this.invoiceDetails"
          :showModal = "false"
          @reloadFn="callInitFn"
          @onClose="onCloseRecordPaymentModal"
      />
      <DeleteInvoiceModal
          :key="showDeleteInvoiceModalKey"
          :itemContent="this.invoiceId"
      />
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import SendInvoiceModal from "@/components/Invoices/modals/SendInvoiceModal";
import RecordPaymentModal from "@/components/Invoices/modals/RecordPaymentModal";
import DeleteInvoiceModal from "@/components/Invoices/modals/DeleteInvoiceModal";
import {mapGetters} from "vuex";
import {formatNumbers} from "@/util/formatNumbers.js";

// services
import {baseURL} from "@/services/config";
import {
  GetDuplicateInvoice,
  GetInvoicePreview,
  GetPackageLimits,
  GetSingleInvoice,
  InvoiceShareLink,
  UpdateInvoice,
} from "@/services/invoice.service";
import {GetCurrentWorkspace} from "@/services/settingsbusiness.service";

export default {
  name: "InvoiceDetails",
  components: {
    HomeLayout,
    SendInvoiceModal,
    RecordPaymentModal,
    DeleteInvoiceModal,
  },
  data() {
    return {
      isLoading: false,
      invoicePreviewUrl: null,
      invoiceShareUrl: null,
      isAnalyticsLoading: false,
      // isShowDeleteItemRow: false,
      showDeleteInvoiceModalKey: Number(new Date()) + 100,
      showRecordPaymentModalKey: Number(new Date()) + 200,
      isApprovedLoading: false,
      currency: localStorage.getItem("CURRENCY"),
      analytics: {
        amountOverdue: "Draft",
        monthOverdue: "Tom Peter",
        avgTime: this.currency + " 5000.00",
        upcomingPayout: "in  4 days",
      },
      invoiceId: null,
      invoiceDetails: {},
      limits: ''
    };
  },
  computed: {
    ...mapGetters({
      getIsInvoiceApproved: "invoiceCreate/getIsInvoiceApproved",
      getUserRole: "auth/getUserRole"
    }),
    isQuote() {
      return window.location.href.toString().includes("quotes");
    },
  },
  async mounted() {
    this.invoiceId =
        this.$route.params.invoiceId !== undefined
            ? this.$route.params.invoiceId
            : window.location.href.toString().split("/")[
            window.location.href.toString().split("/").length - 1
                ];
    await this.loadCurrency();
    await this.initFn(this.invoiceId);
    this.getPackageLimits();
  },
  methods: {
    callInitFn(){
      this.initFn(this.invoiceId);
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
    async getPackageLimits() {
      const res = await GetPackageLimits();
      this.limits = res.data;
      console.log(this.limits)
    },
    numberFormat(val) {
      return formatNumbers(val);
    },
    onCloseRecordPaymentModal() {
      // this.isShowDeleteItemRow = false;
      this.$root.$emit("bv::hide::modal", `record-payment-modal`);
      this.rowItem = null;
    },
    refreshTable() {
      this.initFn(this.invoiceId);
    },
    async convertToInvoice() {
      //TODO: this is temp solution, fix quote id undifined issue
      // let payload = {
      //   id:
      //       this.$route.params.invoiceId !== undefined
      //           ? this.$route.params.invoiceId
      //           : window.location.href.toString().split("/")[
      //           window.location.href.toString().split("/").length - 1
      //               ],
      //   is_invoice: true,
      //   invoice_conversion: true,
      // };
      // try {
      //   this.isApprovedLoading = true;
      //   let {status} = await UpdateInvoice(payload);
      //   if (status === 200) {
      //     let payloadNotify = {
      //       isToast: true,
      //       title: "Quote Converted",
      //       content: "The Quote has been converted to invoice",
      //       variant: "success",
      //     };
      //     this.$store.dispatch("notification/setNotify", payloadNotify);
      //     this.invoiceId = payload.id;
          this.$router.push("/invoices/edit/" + this.invoiceId);
      //   }
      //   this.isApprovedLoading = false;
      // } catch (error) {
      //   this.isApprovedLoading = false;
      //   this.$store.dispatch("invoiceCreate/setIsInvoiceApproved", false);
      //   console.log(error);
      // }
    },
    async loadCurrency() {
      let {data} = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    async initFn(invoiceId) {
      if (invoiceId === undefined) {
        //TODO: this is a temp solution, fix this, quote id undifined
        invoiceId = window.location.href.toString().split("/")[
        window.location.href.toString().split("/").length - 1
            ];
      }
      try {
        this.isAnalyticsLoading = true;
        let {data} = await GetSingleInvoice(invoiceId);

        if (data.status === "draft") {
          this.$store.dispatch("invoiceCreate/setIsInvoiceApproved", false);
        } else {
          this.$store.dispatch("invoiceCreate/setIsInvoiceApproved", true);
        }
        this.invoiceDetails = {
          invoiceId: data._id,
          title: data.title,
          dueDate: data.due_date.split("T")[0],
          status: data.status.charAt(0).toUpperCase() + data.status.slice(1),
          customerName: data.customer_name,
          totalAmount: 0.0,
          items: data.items,
          actions: {
            id: data._id,
            status: data.status,
            title: data.title,
            dueDate: data.due_date.split("T")[0],
          },
          currency: data.currency,
          anchor_currency: data.anchor_currency,
          conversion_rate: data.conversion_rate,
          amount_due:data.amount_due
        };

        this.invoiceDetails.totalAmount = data.items.reduce(function (total, item) {
          const tax = (item.price * item.quantity * item.tax_per_item) / 100
          const preTaxAmount = item.price * item.quantity
          const subTotal = tax + preTaxAmount
          return total + subTotal
        }, 0)


        this.isAnalyticsLoading = false;
      } catch (error) {
        this.isAnalyticsLoading = false;
        console.log(error);
      }
      try {
        let {data} = await GetInvoicePreview(invoiceId);
        this.invoicePreviewUrl = data;
      } catch (error) {
        console.log(error);
      }
    },
    editInvoiceFn() {
      if (this.isQuote) {
        this.$router.push("/quotes/edit/" + this.invoiceId);
      } else {
        this.$router.push("/invoices/edit/" + this.invoiceId);
      }
    },
    async duplicateInvoice() {
      try {
        let {data} = await GetDuplicateInvoice(this.invoiceId);
        if (this.isQuote) {
          this.$router.push("/quotes/edit/" + data.invoice._id);
        } else {
          this.$router.push("/invoices/edit/" + data.invoice._id);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async downloadInvoiceFn() {
      try {
        window.open(`${baseURL}/invoices/download/${this.invoiceId}`);
      } catch (error) {
        console.log(error);
      }
    },
    async approveInvoice() {
      if (this.limits.remaining_invoice === 0 && !this.isQuote) {
        return
      }
      let payload = {
        id:
            this.$route.params.invoiceId !== undefined
                ? this.$route.params.invoiceId
                : this.invoiceId,
        status: "unsent",
        items: this.invoiceDetails.items,
      };
      try {
        this.isApprovedLoading = true;
        let {status} = await UpdateInvoice(payload);
        if (status === 200) {
          let quote = "The Quote draft has been approved successfully";
          let invoice = "The Invoice draft has been approved successfully";
          let payloadNotify = {
            isToast: true,
            title: "Approve " + this.isQuote ? "Quote" : "Invoice",
            content: this.isQuote ? quote : invoice,
            variant: "success",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
          if (!this.isQuote) {
            this.$store.dispatch("invoiceCreate/setIsInvoiceApproved", true);
          }
          await this.initFn(this.invoiceId);
        }
        this.isApprovedLoading = false;
        this.getPackageLimits();
      } catch (error) {
        this.isApprovedLoading = false;
        this.$store.dispatch("invoiceCreate/setIsInvoiceApproved", false);
        console.log(error);
      }
    },
    openSendInvoiceModal(button) {
      this.$store.dispatch("invoiceCreate/setIsActiveShare", false);
      this.getInvoiceShareLink();
      this.$root.$emit("bv::show::modal", `send-invoice-modal`, button);
    },
    openShareLinkModal(button) {
      this.$store.dispatch("invoiceCreate/setIsActiveShare", true);
      this.getInvoiceShareLink();
      this.$root.$emit("bv::show::modal", `send-invoice-modal`, button);
    },
    async getInvoiceShareLink() {
      try {
        let payload = {
          id: this.invoiceId,
        };
        let {data, status} = await InvoiceShareLink(payload);
        if (status === 200) {
          this.invoiceShareUrl = data.url;
        }
      } catch (error) {
        console.log(error);
      }
    },
    openRecordPaymentModal(button) {
      this.$root.$emit("bv::show::modal", `record-payment-modal`, button);
    },
    openDeleteInvoiceModal(button) {
      this.$root.$emit("bv::show::modal", "delete-invoice-row-modal", button);
    },
    printInvoice() {
      window.print();
    },
  },
};
</script>

<style scoped>
@media print {
  @page {
    size: auto; /* auto is the initial value */
    margin: 20px; /* this affects the margin in the printer settings */
  }
}

.space-top {
  margin-top: 50px;
}

.disabled-button1 {
  background-color: #bdbdbd;
  border: 1px solid #bdbdbd;
}

.button-colour1 {
  background-color: #0b9b7d;
}

.disabled-button2 {
  color: #bdbdbd;
  border: 1.8px solid #bdbdbd;
  background-color: #ffffff;
}

.button-colour2 {
  color: #0b9b7d;
  border: 1px solid #0b9b7d;
  background-color: #ffffff;
}

.button-colour2:hover {
  background-color: rgb(233, 231, 231);
}

/* insights card */
.edit-invoice-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
}

.insight-cards-row {
  padding: 25px;
  height: 130px;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--dark);
  margin-bottom: 20px;
}

.insight-cards-row .status-button {
  border: 1px solid #0b9b7d;
  border-radius: 30px;
}

/* table card */
.table-card-row {
  height: 100%;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

/* create-invoice-row */
.table-card-row .table-container-card >>> .create-invoice-row {
  /* padding: 25px; */
  padding: 32px 10px;
  height: 140px;
  width: 100%;
  /* background-color: var(--white); */
  border-radius: 5px;
  border: 1px solid var(--dark);
  /* margin-bottom: 20px; */
}

/* preview-invoice-row */
.table-card-row .table-container-card >>> .preview-invoice-row {
  /* padding: 25px; */
  /* padding: 32px 25px; */
  height: auto;
  width: 100%;
  /* background-color: var(--info); */
  border-radius: 5px;
  border: 1px solid var(--dark);
  /* margin-bottom: 20px; */
}

.table-card-row
.table-container-card
.preview-invoice-row
>>> .preview-invoice {
  height: auto;
  width: 100%;
}

.table-card-row .table-container-card >>> .tab-wrapper {
  /* border-bottom: 0.5px solid var(--gray-750); */
  border-bottom: none;
}

.table-card-row .table-container-card >>> .invoice-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  /* background-color: var(--ghost-white); */
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 20px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray); */
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link:hover {
  background-color: var(--light);
  color: #0b9b7d;
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link.active {
  background-color: var(--light);
  border-bottom: 2px solid #0b9b7d;
}

.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: #0b9b7d;
}

.table-card-row .customer-dropdown {
  height: 40px;
  min-width: 270px;
  max-width: 350px;
}

.table-card-row .status-dropdown {
  height: 40px;
  width: 200px;
  /* min-width: 270px;
  max-width: 350px; */
}

.table-card-row .date-picker {
  height: 40px;
  width: 150px;
  /* min-width: 150px;
  max-width: 200px; */
}

.table-card-row .filter-dropdown >>> .filter-dropdown-menu,
.table-card-row .export-dropdown >>> .export-dropdown-menu {
  min-width: 200px;
}

.table-card-row .export-dropdown >>> .export-dropdown-menu .inner-icon,
.table-card-row .filter-dropdown >>> .filter-dropdown-menu .inner-icon {
  width: 8px;
  height: 8px;
}

.table-card-row
.filter-dropdown
>>> .filter-dropdown-menu
button.dropdown-item.active,
.table-card-row
.filter-dropdown
>>> .filter-dropdown-menu
button.dropdown-item:active,
.table-card-row
.export-dropdown
>>> .export-dropdown-menu
button.dropdown-item.active,
.table-card-row
.export-dropdown
>>> .export-dropdown-menu
button.dropdown-item:active {
  background-color: var(--light);
}

.table-card-row .export-dropdown >>> .export-dropdown-button {
  padding: 6px 20px;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .insight-cards-row {
    height: 230px;
  }

  .table-card-row .customer-dropdown,
  .table-card-row .status-dropdown {
    /* min-width: auto; */
    min-width: 200px;
    max-width: 350px;
  }

  .table-card-row .search-input-group {
    /* max-width: none; */
    width: 200px;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .insight-cards-row {
    height: 400px;
  }

  .table-card-row .customer-dropdown,
  .table-card-row .status-dropdown {
    /* min-width: auto; */
    width: 350px;
  }

  .table-card-row .search-input-group {
    /* max-width: none; */
    width: 500px;
  }
}
</style>
