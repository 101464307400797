<template>
  <b-row
      class="d-flex flex-row align-items-start justify-content-start"
      no-gutters
  >
    <b-row
        class="d-flex flex-row align-items-start justify-content-start px-3 w-100 table-container-row"
        no-gutters
    >
      <b-col>
        <b-table
            :bordered="false"
            :borderless="true"
            hover
            show-empty
            class="table-element"
            thead-class="table-header font-info"
            tbody-class="table-body"
            :busy="isLoading"
            :per-page="perPage"
            :fields="invoiceHeader"
            :items="invoiceItems"
            sortBy="userId"
            :sortDesc="true"
        >
          <!-- table busy state -->
          <template #table-busy>
            <div class="text-center text-muted my-3">
              <b-spinner class="align-middle"></b-spinner>
              <span class="text-left mx-1">Loading...</span>
            </div>
          </template>
          <!-- table empty state -->
          <template #empty="scope">
            <b-row
                class="d-flex flex-row align-items-center justfiy-content-center py-5"
                no-gutters
            >
              <h6 class="text-center mb-0 mx-auto my-5">
                {{ scope.emptyText }}
              </h6>
            </b-row>
          </template>
          <!-- invoice status  -->
          <template #cell(statusDetails)="data">
            <!-- <span class="main-text text-dark mb-2">{{ data.value }}</span> -->
            <router-link
                class="d-flex flex-column align-items-start justify-content-center text-decoration-none"
                :to="{
              name: 'Invoice #',
              params: { invoiceId: data.value.id },
            }"
            >
            <span
                class="d-flex flex-row align-items-center justify-content-start ml-0 invoice-status-badge"
            >
              <i
                  class="status-indicator mx-2"
                  :class="
                  data.value.status == 'Draft' ? 'bg-gray-750' : 'bg-primary'
                "
              ></i>
              <!-- {{ data.value.status > 0 ? "Draft" : "UnPaid" }} -->
              {{ data.value.status }}
            </span>
            </router-link>
          </template>
          <!-- invoice date  -->
          <template #cell(dateDetails)="data">
            <router-link
                class="d-flex flex-column align-items-start justify-content-center text-decoration-none"
                :to="{
              name: 'Invoice #',
              params: { invoiceId: data.value.id },
            }"
            >
              <span class="main-text text-dark mb-2">{{ formatDate(data.value.date) }}</span>
            </router-link>
          </template>
          <!-- invoice number  -->
          <template #cell(numberDetails)="data">
            <router-link
                class="d-flex flex-column align-items-start justify-content-center text-decoration-none"
                :to="{
              name: 'Invoice #',
              params: { invoiceId: data.value.id },
            }"
            >
            <span class="main-text text-dark mb-2">{{
                data.value.number
              }}</span>
            </router-link>
          </template>
          <!-- customer Name  -->
          <template #cell(customerDetails)="data">
            <router-link
                class="d-flex flex-column align-items-start justify-content-center text-decoration-none"
                :to="{
              name: 'Invoice #',
              params: { invoiceId: data.value.id },
            }"
            >
            <span class="main-text text-dark mb-2">{{
                data.value.customerName
              }}</span>
            </router-link>
          </template>
          <!-- totalAmount  -->
          <template #cell(totalAmount)="data">
            <router-link
                class="d-flex flex-column align-items-start justify-content-center text-decoration-none"
                :to="{
              name: 'Invoice #',
              params: { invoiceId: data.value.id },
            }"
            >
            <span class="main-text text-dark mb-2"
            >{{ data.value.currency }}{{ numberFormat(data.value.totalAmount) }}
            </span>
              <p v-if="data.value.conversion_rate" style="font-size: x-small" class="action-text">{{ (data.value.totalAmount / data.value.conversion_rate).toFixed(2) }}
                ({{ data.value.anchor_currency }}) at {{data.value.conversion_rate}}</p>
            </router-link>
          </template>
          <!-- amount Due  -->
          <template #cell(amountDetails)="data">
            <router-link
                class="d-flex flex-column align-items-start justify-content-center text-decoration-none"
                :to="{
              name: 'Invoice #',
              params: { invoiceId: data.value.id },
            }"
            >
            <span class="main-text text-dark mb-2"
            >{{ data.value.currency }}{{ numberFormat(data.value.amountDue) }}
            </span>
              <p v-if="data.value.conversion_rate" style="font-size: x-small" class="action-text">{{ (data.value.amountDue / data.value.conversion_rate).toFixed(2) }}
                ({{ data.value.anchor_currency }}) at {{data.value.conversion_rate}}</p>
            </router-link>
          </template>
          <!-- actions column -->
          <template #cell(actions)="data">
            <!-- <router-link
              class="d-flex flex-column align-items-start justify-content-center text-decoration-none"
              :to="{
                name: 'Invoice #',
                params: { invoiceId: data.value.id },
              }"
            > -->
            <b-dropdown
                id="dropdown-left"
                text="Left align"
                toggle-class="shadow-sm border border-prime-gray more-btn rounded-circle"
                no-caret
                right
            >
              <template #button-content>
                <!-- <b-button
                    variant="outline-dark"
                    v-b-tooltip.hover.left
                    title="View More Actions"
                    class="shadow-sm ml-0 border border-prime-gray more-btn"
                    pill
                  > -->
                <b-icon
                    icon="chevron-down"
                    aria-label="View More Actions"
                    class="mx-auto more-icon"
                    size="lg"
                    font-scale="1.2"
                ></b-icon>
                <!-- </b-button> -->
              </template>
              <template v-if="data.value.status == 'draft'">
                <b-dropdown-item-button @click="approveInvoiceFn(data.value.id)"
                                        :disabled="getUserRole.role.includes('User')"
                ><span class="text-main-green font-weight-bold"
                >Approve</span>
                  <b-icon
                      v-if="limits.remaining_invoice === 0"
                      icon="lock-fill"
                      class=""
                      scale="1.1"
                      aria-hidden="true"
                      variant="warning"
                      title="You've reached to the maximum invoice limit on your package"
                  ></b-icon>
                </b-dropdown-item-button
                >
              </template>
              <b-dropdown-item-button @click="viewInvoiceFn(data.value.id)"
              >View
              </b-dropdown-item-button
              >
              <b-dropdown-item-button @click="editInvoiceFn(data.value.id)"
                                      :disabled="getUserRole.role === 'User'"
              >Edit
              </b-dropdown-item-button
              >
              <b-dropdown-item-button :disabled="getUserRole.role.includes('User') "  @click="duplicateInvoice(data.value.id)">Duplicate</b-dropdown-item-button>
              <b-dropdown-item-button :disabled="getUserRole.role.includes('User')">Send</b-dropdown-item-button>
              <b-dropdown-item-button
                  button-class="text-danger"
                  @click="openDeleteInvoiceModal(data.item, $event.target)"
                  :disabled="getUserRole.role.includes('User')"
              >Delete
              </b-dropdown-item-button
              >
            </b-dropdown>
            <!-- </router-link> -->
          </template>
        </b-table>
      </b-col>
    </b-row>
    <!-- table controls -->
    <!-- safari fix (flex-shrink-0) -->
    <b-row
        class="d-flex flex-row align-items-center justify-content-center justify-content-md-end flex-shrink-0 px-3 py-2 w-100"
        no-gutters
    >
      <!-- pages per page control -->
      <b-col
          class="d-flex flex-column align-items-center align-items-sm-start align-items-md-end align-items-xl-start justify-content-center py-2 py-md-1"
          cols="12"
          sm="6"
          lg="5"
          xl="2"
      >
        <b-form-group
            label="Per page"
            label-cols="12"
            label-cols-sm="6"
            label-align="left"
            label-align-sm="right"
            label-size="sm"
            label-for="perPageSelect"
            class="mb-0 w-100"
        >
          <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              class="per-page-select border-1 border-main-green py-0"
              :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <!-- pagination controls -->
      <b-col
          class="d-flex flex-column align-items-center align-items-sm-end justify-content-center py-2 py-md-1"
          cols="12"
          sm="6"
          lg="4"
          xl="3"
      >
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="md"
            limit="4"
            pills
            @change="loadPage"
            class="table-pagination"
            page-class="custom-page-item"
            ellipsis-class="custom-page-item"
            first-class="custom-page-item"
            last-class="custom-page-item"
            next-class="custom-page-item"
            prev-class="custom-page-item"
        >
          <template #first-text>
            <b-icon
                class="text-main-green"
                icon="chevron-left"
                size="sm"
                aria-label="Plus"
            ></b-icon>
          </template>
          <template #prev-text>
            <b-icon
                class="text-main-green"
                icon="chevron-double-left"
                size="sm"
                aria-label="Plus"
            ></b-icon>
          </template>
          <template #next-text>
            <b-icon
                class="text-main-green"
                icon="chevron-double-right"
                size="sm"
                aria-label="Plus"
            ></b-icon>
          </template>
          <template #last-text>
            <b-icon
                class="text-main-green"
                icon="chevron-right"
                size="sm"
                aria-label="Plus"
            ></b-icon>
          </template>
        </b-pagination>
      </b-col>
    </b-row>
    <DeleteInvoiceModal
        :key="showDeleteInvoiceModalKey"
        :itemContent="this.invoiceId"
        @reloadFn="callInitFn"
        @onClose="onCloseDeleteInvoiceModal"
    />
  </b-row>
</template>

<script>
import DeleteInvoiceModal from "@/components/Invoices/modals/DeleteInvoiceModal";
// services
import {GetPackageLimits, UpdateInvoice ,GetDuplicateInvoice,} from "@/services/invoice.service";
import {GetCurrentWorkspace} from "@/services/settingsbusiness.service";
import {formatNumbers} from "@/util/formatNumbers.js";
import {mapGetters} from "vuex";

export default {
  name: "InvoicesDraftList",
  components: {
    DeleteInvoiceModal,
  },
  props: {
    invoiceItems: Array,
    isLoading: Boolean,
    totalRows: Number,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      invoiceId: null,
      isShowDateFilterModal: false,
      showDeleteInvoiceModalKey: Number(new Date()) + 100,
      rowItem: null,
      rowIndex: null,
      searchTable: null,
      isCountryManager: false,
      adminDetails: null,
      selectedCountryCode: null,
      filterDropDownShow: false,
      exportDropDownShow: false,
      isAnalyticsLoading: false,
      isDateFilter: false,
      invoiceHeader: [
        {
          key: "statusDetails",
          label: "Status",
          sortable: true,
          class: "text-left",
        },
        {
          key: "dateDetails",
          label: "Date",
          sortable: true,
        },
        {
          key: "numberDetails",
          label: "Number",
          sortable: true,
        },
        {
          key: "customerDetails",
          label: "Customer",
          sortable: true,
        },
        {
          key: "totalAmount",
          label: "Total",
          sortable: true,
          // class: "text-right",
        },
        {
          key: "amountDetails",
          label: "Amount Due",
          sortable: true,
          // class: "text-right",
        },
        {
          key: "actions",
          label: "Action",
          // class: "text-right",
        },
      ],
      //invoiceItems: [
      // {
      //   status: 1,
      //   date: "2020-10-11",
      //   number: 3,
      //   customerName: "A,G. Peter",
      //   amountDue: "LKR 5,000.00",
      // },
      //],
      analytics: {
        totalUsers: null,
        mostPopularRegion: {},
        topParticipant: {},
      },
      // table pagination controls
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20],
      currency: localStorage.getItem("CURRENCY"),
      limits: ''
    };
  },
  watch: {
    perPage() {
      this.loadPage(1);
    },
  },
  created() {
    this.$root.$refs.InvoicesDraftList = this;
  },
  async mounted() {
    // await this.loadCurrentWorkspace();
    // await this.initFn();
    this.getPackageLimits();
  },
  methods: {
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
    async getPackageLimits() {
      const res = await GetPackageLimits();
      this.limits = res.data;
      console.log(this.limits)
    },
    async loadCurrentWorkspace() {
      let {data} = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    numberFormat(val) {
      return formatNumbers(val);
    },
    async approveInvoiceFn(_id) {
      if (this.limits.remaining_invoice === 0) {
        return
      }
      let payload = {
        id: _id,
        status: "unsent",
        status_only: true,
      };
      try {
        let {status} = await UpdateInvoice(payload);
        if (status == 200) {
          // console.log(data);
          this.$emit("loadPage", {
            perPage: this.perPage,
            currentPage: this.currentPage,
          });
          this.$emit("reloadFn", {
            perPage: this.perPage,
            currentPage: this.currentPage,
          });
        }
        this.$emit('reloadFn');
      } catch (error) {
        console.log(error);
      }
    },
    editInvoiceFn(id) {
      console.log("clicked", id);
      this.$router.push("/invoices/edit/" + id);
    },
    async duplicateInvoice(_id) {
      // console.log("🚀 ~ file: InvoicesAllList.vue:645 ~ duplicateInvoice ~ _id:", _id)
      // console.log("duplicate id1", this.invoiceId);
      try {
        let { data } = await GetDuplicateInvoice(_id);
        console.log("duplicate data", data.invoice._id);
        this.$router.push("/invoices/edit/" + data.invoice._id);
      } catch (error) {
        console.log(error);
      }
    },
    viewInvoiceFn(id) {
      console.log("clicked", id);
      this.$router.push("/invoices/" + id);
    },
    openDeleteInvoiceModal(item, button) {
      // this.$store.dispatch("setSelectedItem", item);
      console.log("item", item.actions.id);
      this.invoiceId = item.actions.id;
      this.isShowDeleteItemRow = true;
      this.rowItem = item;
      this.$root.$emit("bv::show::modal", "delete-invoice-row-modal", button);
    },
    onCloseDeleteInvoiceModal() {
      this.isShowDeleteItemRow = false;
    },
    callInitFn() {
      this.showDeleteInvoiceModalKey = Number(new Date()) + 100;
      this.$emit("reloadFn");
    },
    loadPage(value) {
      this.currentPage = value;
      this.$emit("loadPage", {
        perPage: this.perPage,
        currentPage: this.currentPage,
      });
    },
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole"
    }),
    getCurrency() {
      if (this.currency) {
        return this.currency;
      }
      return "";
    },
  },
};
</script>

<style scoped>
/* insights card */
.insight-cards-row {
  height: 150px;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--dark);
  margin-bottom: 30px;
}

/* table card */
.table-card-row {
  height: 100%;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: #0b9b7d;
}

.table-card-row .filter-dropdown,
.table-card-row .export-dropdown {
  height: 40px;
  min-width: 330px;
  max-width: 350px;
}

.table-card-row .filter-dropdown >>> .filter-dropdown-menu,
.table-card-row .export-dropdown >>> .export-dropdown-menu {
  min-width: 200px;
}

.table-card-row .export-dropdown >>> .export-dropdown-menu .inner-icon,
.table-card-row .filter-dropdown >>> .filter-dropdown-menu .inner-icon {
  width: 8px;
  height: 8px;
}

.table-card-row
.filter-dropdown
>>> .filter-dropdown-menu
button.dropdown-item.active,
.table-card-row
.filter-dropdown
>>> .filter-dropdown-menu
button.dropdown-item:active,
.table-card-row
.export-dropdown
>>> .export-dropdown-menu
button.dropdown-item.active,
.table-card-row
.export-dropdown
>>> .export-dropdown-menu
button.dropdown-item:active {
  background-color: var(--light);
}

.table-card-row .export-dropdown >>> .export-dropdown-button {
  padding: 6px 20px;
}

/* table container */
.table-container-row {
  min-height: 350px;
  overflow-x: auto;
  /* overflow-y: hidden; */
  scrollbar-width: thin;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 1000px;
}

/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

.table-element >>> .table-body td {
  vertical-align: middle;
}

.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}

.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

/* hyperlink custom styling */
.table-element >>> .table-body td {
  vertical-align: middle;
  padding: 0;
}

.table-element >>> .table-body td > a {
  padding: 0.75rem;
}

.table-element >>> .table-body .status-badge {
  font-size: 0.8rem;
  min-width: 95px;
}

.table-element >>> .table-body .invoice-status-badge {
  font-size: 0.8rem;
  color: #222831;
}

.table-element >>> .table-body .invoice-status-badge i.status-indicator {
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.table-element >>> .table-body .sub-badge-text {
  font-size: 0.55rem;
}

.table-element >>> .table-body .product-img img {
  object-fit: cover;
}

/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: #0b9b7d;
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}

/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}

/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-color: transparent;
  border-radius: 0;
  color: var(--dark);
  padding: 0.5rem 0.6rem;
  font-size: 18px;
  height: 20px;
  transition: all 150ms ease-in;
}

.table-pagination >>> .custom-page-item button:hover {
  color: #0b9b7d;
  /* border-color: var(--secondary); */
  background-color: var(--light);
}

.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: #0b9b7d;
  border: none;
  border-color: transparent;

  font-weight: bold;
  background-color: transparent;
}

.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  opacity: 0.5;
  border-color: transparent;
  background-color: transparent;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .table-card-row .filter-dropdown,
  .table-card-row .export-dropdown {
    min-width: auto;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .insight-cards-row {
    height: auto;
  }

  .table-card-row .search-input-group {
    max-width: none;
  }
}
</style>
