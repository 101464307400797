import axios from "axios";

export const createStripAccount = async () => {
    try {
        return await axios.get("stripe/create_stripe_account");
    } catch (error) {
        return error;
    }
};

export const checkStripAccountStatus = async () => {
    try {
        return await axios.get("stripe/check_stripe_account_status");
    } catch (error) {
        return error;
    }
};

export const getAccountLink = async (id) => {
    try {
        return await axios.get(`stripe/account_link/${id}`);
    } catch (error) {
        return error;
    }
};

export const getPackageInfo = async (isAnnual) => {
    try {
        return await axios.get(`packages/stripe_packages/${isAnnual}`);
    } catch (error) {
        return error;
    }
};

export const invoicePayment = async (payload) => {
    try {
        return await axios.post('stripe/invoice/payment',payload);
    } catch (error) {
        return error;
    }
};

export const getInvoice = async (id) => {
    try {
        return await axios.get(`stripe/invoice/${id}`);
    } catch (error) {
        return error;
    }
};
